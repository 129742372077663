import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'

const AboutPage = ({ data }) => {
  return (
    <Layout pageTitle="About S&amp;A Innovations">
      <div className="container mb-16">
        <section className="mb-10">
          <h2 class="underline">Our story</h2>
          <p className="text-lg mb-5">
            My name is Seth Davis, and I have been in the construction trade since 2006.
          </p>
          <p className="text-lg mb-5">
            In 2020, there were many changes that occurred for everyone. After my wife Ashley and I welcomed our firstborn into the world, I felt a strong desire for personal and professional growth. In 2021, I decided to follow a lifelong dream of mine. This is where the story begins for <strong>S&amp;A Innovations</strong>.
          </p>
          <p className="text-lg mb-5">
            For my customers, I strive to provide the best possible service and quality craftsmanship.
          </p>
          <p className="text-lg mb-5">
            For my employees and subcontractors, my goal is to provide a positive, organized, and safe work environment.
          </p>
        </section>
        <section className="mb-10">
          <h2 className="underline">Our craft</h2>
          <p className="text-lg mb-5">
            We take great pride in our craftsmanship. We work with local companies and use trusted brands for our projects.
          </p>
          <p className="text-lg mb-5">
            The companies we rely upon for our construction include the following well known brands:
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 items-center mb-5">
          {
            data.allFile.nodes.map((node) => {
              const image = getImage(node)
              return (
              <div key={node.id}>
                <GatsbyImage image={image} alt="" />
              </div>)
            })
          }
          </div>
          <small className="text-sm mb-5">
            All trademarks, logos, and brand names are property of their respective owners. Their mention does not imply endorsement nor affiliation. They merely represent the companies whose products and services we use in our projects.
          </small>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
query {
  allFile(sort: { fields: name }, filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "about/logos"}}) {
    nodes {
      id
      name
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          formats: [AUTO]
        )
      }
    }
  }
}
`

export default AboutPage